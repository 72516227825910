body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.error {
	background: pink;
	border: 1px solid red;
	margin-top: 15px;
	padding: 5px;
	border-radius: 4px;
}

.it-navbar {
	background-color: #563d7c;
}

.it-navbar .navbar-brand {
	font-weight: 500;
}

.it-navbar .navbar-nav .nav-link {
	color: #cbbde2;
	font-weight: 500;
	padding-right: .5rem;
	padding-left: .5rem;
}

.it-navbar .navbar-nav .nav-link:hover {
	color: #e8d8ff;
}

.it-navbar .navbar-nav .nav-link.active {
	color: #fff;
}

.it-btn {
	background-color: #563d7c;
	font-weight: 450;
	color: #fff;
}

.it-btn:hover {
	color: #fff;
	background-color: #664d8c;
}

.it-hover .it-hover-target {
	display: none;
}

.it-hover:hover .it-hover-target {
	display: inline-block;
}

.it-btn-outline {
	font-weight: 450;
	color: #664d8c;
	background-color: transparent;
	border-color: #664d8c;
}

.it-btn-outline:hover {
	color: #fff;
	background-color: #664d8c;
}

.it-selectable {
	cursor: pointer;
}

.it-dropdown {
	position: relative;
}

.it-dropdown-menu {
	top: 95%;
	border-top-left-radius: 0;
}

.it-disabled-input:disabled {
	background: #fff;
	text-overflow: ellipsis;
	overflow: hidden;
}

.it-table {
	width: auto;
}

.it-table-fixed thead {
	display: block;
	border-bottom: 1px solid #ccc;
	margin-bottom: 4px;
}

.it-table-fixed tbody {
	display: block;
	overflow: scroll;
	height: 500px;
}

th.it-th {
	padding-left: 10px;
	border-bottom: 1px solid #888;
}

th.it-th-1 {
	min-width: 206px;
}

td.it-td {
	border: 1px solid #ccc;
	cursor: pointer;
}

td.it-td-1 {
	min-width: 206px;
}

.attr-on {
	background: #afa;
}

.attr-off {}

th.rotate {
	height: 230px;
	white-space: nowrap;
}

th.rotate>div {
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	width: 30px;
}

th.rotate>div>span {
	padding: 5px 5px;
}

.lock {
	float: right;
	font-style: italic;
	font-size: 8pt;
	width: 124px;
}

.lock img {
	cursor: pointer;
	opacity: 0.75;
	height: 32px;
	float: right;
}

img.locked {
	padding-right: 4.6px;
}

img.unlocked {
	padding-right: 0px;
}
